<template>
    <div class="min-vh-100 d-flex flex-column">
        <div class="container">
            <div class="w-100 min-vh-100 pb-5 pb-md-0">
                <div class="d-flex justify-content-end">
                    <ul class="list-unstyled d-flex navbar">
                        <li v-for="(item, index) in locales"
                            class="text-uppercase px-2 nav-item"
                            :class="{'border-end' : index < locales.length - 1}">
                                <span @click="changeLocale(item)" class="text-decoration-none nav-link px-2 cursor-pointer"
                                      :class="{'router-link-active' : locale === item.code }">
                                    {{ item.code }}
                                </span>
                        </li>
                    </ul>
                </div>
                <slot/>
            </div>
            <FooterLinks :absolute="false" class="d-flex"/>
        </div>
    </div>
</template>
<script setup>
import FooterLinks from "~/components/layouts/FooterLinks.vue";

import {useNuxtApp} from "nuxt/app";
import {setLocale} from '@vee-validate/i18n';

const {locale, locales} = useI18n()
const preferredLocale = useNuxtApp().$i18n.getLocaleCookie() || useNuxtApp().$i18n.getBrowserLocale()

function changeLocale(newLocale) {
    useNuxtApp().$i18n.setLocale(newLocale.code);
    setLocale(newLocale.code);
}

onMounted(() => {
    useNuxtApp().$i18n.setLocale(preferredLocale)
})
</script>

<style scoped>
.text-footer {
    font-size: 0.9rem;
}
</style>